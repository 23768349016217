import { ErpTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '提交时间',
      prop: 'createdTime',
      propDesc: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '客户',
      prop: 'customerName',
      propDesc: 'customerName',
      minWidth: 298,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '用户名',
      prop: 'userName',
      propDesc: 'userName',
      minWidth: 120,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '意见反馈',
      prop: 'content',
      propDesc: 'content',
      value: '',
      minWidth: 800,
      type: 'input', // 筛选类型 input 输入项
    },
  ];
  return { columnList, tableRef };
};
