import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!

  return (_openBlock(), _createBlock(_component_ErpList, {
    url: "/malicrm/app/user/queryUserFeedBackListWithPage",
    columOptions: _ctx.columnList,
    ref: "tableRef",
    id: "erpFeedback",
    onRowClick: _ctx.rowClick
  }, null, 8, ["columOptions", "onRowClick"]))
}