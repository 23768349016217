
import { defineComponent } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './useListData';
export default defineComponent({
  name: 'erpFeedback',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };

    // 查看详情
    const rowClick = () => {
      return true;
    };

    return {
      columnList,
      tableRef,
      refreshTable,
      rowClick,
    };
  },
});
